import React from "react";
import { Col, Row } from "antd";

class ContentLists extends React.Component {
  render() {
    return (
      <div className="slide-content">
        <Row>
          <Col xs={24} lg={12}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/to-do-list-640.webp"
              ></source>
              <source
                type="image/jpg"
                srcSet="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/to-do-list-640.jpg"
              ></source>
              <img
                src="https://s3-us-west-2.amazonaws.com/dayviewer/img/home/dvnext/contentslides/to-do-list-640.jpg"
                className="homepage-image"
                alt="online todo list"
                title="online todo list"
                width="100%"
                height="100%"
              />
            </picture>
          </Col>
          <Col xs={24} lg={12}>
            <h2>Online To Do Lists</h2>

            <p>
              DayViewer has a variety of online to do list features. The Hitlist
              To Do List feature (unlocked in Pro) enables a quick and easy way
              to create daily lists. Action them before the day is done and it
              counts as a hit. Give yourself more to do every day and get the
              things you need to get done - done.
            </p>
            <p>
              You can also use the checklists within the notes feature to make
              list templates attachable into your calendar - very handy for
              shopping lists, recipes, fitness routines, procedures or revision
              checklists you would likely re-use.
            </p>
            <p>
              Lists in tasks will help you breaking larger tasks into manageable
              subtasks.
            </p>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ContentLists;
